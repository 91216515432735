import { ClientMetaFunction, mergeMeta } from '@app/utils/remix/play-now-meta';
import { getRemixMeta } from '@play-now/video/components/Meta/PlayMetaData';
import { useInitialData } from '@play-now/video/config/VideoAppContext';
import { HomePage } from '@play-now/video/pages/Home/HomePage';
import { getSearchQueryUrl } from '@play-now/video/pages/Search/SearchPage.route';
import { json, LoaderFunctionArgs } from '@remix-run/node';

import { PlayLayout } from './PlayLayout';
import { setupPlayUtils } from './remix-route-helper';

export const loader = async (args: LoaderFunctionArgs) => {
  const { config, i18n } = setupPlayUtils(args);
  const metaData = getRemixMeta({
    config,
    'script:ld+json': {
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      url: config.baseUrl,
      potentialAction: {
        '@type': 'SearchAction',
        target: `${getSearchQueryUrl(config.baseUrl, i18n, '{search_term_string}')}`,
        'query-input': 'required name=search_term_string',
      },
    },
  });
  if (config.apps?.ios.showAppBanner) {
    metaData.push({ name: 'apple-itunes-app', content: `app-id=${config.meta.iosAppStoreId}` });
  }
  return json({ meta: metaData });
};

export const meta: ClientMetaFunction<typeof loader> = ({ data, matches }) => mergeMeta({ data, matches });

// Exporting an empty client loader enables instant client-side navigation.
export const clientLoader = async () => ({});

const HomePageRoute = () => {
  const initialData = useInitialData();
  return (
    <PlayLayout pageId='home'>
      <HomePage initialData={initialData} />
    </PlayLayout>
  );
};

export default HomePageRoute;
